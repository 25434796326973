
import React, { ReactNode } from "react";
import { Flex, Text } from "@chakra-ui/react";
interface Props{
    title: string
    children?: ReactNode
}
export function HeaderContent({title, children}: Props){

    return(
        <Flex
            p="16px 12px"
            alignItems={"center"}
            gap="12px"
            alignSelf={"stretch"}
            justifyContent="space-between"
            borderRadius="8px"
            bg="#F9FAFB"
        >
            <Text                
                color="#0D0C0C"
                fontSize="32px"
                fontWeight={600}
                lineHeight="123.529%"
                letterSpacing="-0.68px"
            >{title}</Text>
            {children&&children}
        </Flex>
    )
}