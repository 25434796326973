import React, { useEffect, useState } from "react";
import {
  VStack,
  Text,
  Button,
  Center,
  Divider,
  Stack,
  Flex,
  Box,
  HStack
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";


import { Player } from "@lottiefiles/react-lottie-player";
import { Template } from "../../componentes/template";
import { isMobile } from "react-device-detect";
import { useQuestions } from "../../contexts/QuestionsProvider/useQuiz";
import { IUser } from "../../contexts/AuthProvider/types";
import { calcularIdade } from "../../utils/calculaIdade";
import { ITests, ITestsByUsers } from "../../contexts/QuestionsProvider/types";
import { identificarCampoComMaiorNumero } from "../../utils/identificarCampoComMaiorNumero";
import { identificarLetraComMaiorNumero } from "../../utils/identificarLetraComMaiorNumero";
import { HeaderContent } from "../../componentes/content/header";
import { BodyContent } from "../../componentes/content/body";


// Lazy load components
const BrainParts = React.lazy(() => import("./componentsResult/brainParts"));
const BrainAnalysis = React.lazy(() => import("./componentsResult/brainAnalysis"));
const BrainChart = React.lazy(() => import("./componentsResult/brainChart"));
const PerfilText = React.lazy(() => import("./componentsResult/perfilText"));

export function ResultAdmin() {
  const navigate = useNavigate();
  const { questions, responseQuestions } = useQuestions()
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const resultRoute: ITestsByUsers = location.state?.data;
  const resultData: ITestsByUsers = resultRoute?resultRoute:{    
    id: "",
    email: "",
    fone:"",
    is_admin:false,
    nascimento: "",
    nome_completo: "",
    ocupacao:"",
    sexo: "",
    test: []
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);
 
  
  const fone = `${process.env.REACT_APP_PHONE_ANALISTA}`

  const handleWhatsApp = () => {
      const message = `Olá, me chamo ${resultData.nome_completo.toLocaleUpperCase()}, sexo ${resultData.sexo}, ${calcularIdade(new Date(resultData.nascimento))} anos, ocupação ${resultData.ocupacao.toLocaleUpperCase()}, o meu perfi é ${String(resultData.test[0].perfil).toLocaleUpperCase()}.
  Resultados: ${resultData.test[0].i_influente}% influente, ${resultData.test[0].c_estavel}% estável, ${resultData.test[0].a_dominante}% dominante e ${resultData.test[0].o_analitico}% analítico. Pensante ${((resultData.test[0].o_analitico + resultData.test[0].i_influente) / 2)}%, emoção ${((resultData.test[0].i_influente + resultData.test[0].c_estavel) / 2) }%, atuante ${((resultData.test[0].c_estavel + resultData.test[0].a_dominante) / 2)}% e razão ${((resultData.test[0].o_analitico + resultData.test[0].a_dominante) / 2)}%.
  `

      if (isMobile) {
        // Abrir o aplicativo do WhatsApp no celular
        window.open(`whatsapp://send?phone=${fone}&text=${message}`)
      } else {
        // Abrir o WhatsApp Web no computador
        window.open(`https://web.whatsapp.com/send?phone=${fone}&text=${message}`, '_blank')
      }

    }
  
  
  const gerarPDF = (resultData: ITests) => {
    localStorage.setItem("@result-test", JSON.stringify(resultData))
    window.open(`/result/pdf`, '_blank');
    // navigate('/result/pdf', {state:{resultData}})
  };


  return (
    <Template title=" ">
      {isLoading ? (
        <Center w={"100vw"} h={"100vh"} pos={"fixed"} mt={-20}>
          <Player
            src="https://lottie.host/d8a484d2-1443-4d23-8f14-bb518494302c/qWhNCg22gy.json"
            className="player"
            loop
            autoplay
            style={{
              height:"350px"
            }}
          />
        </Center>
      ) : (
        <Flex 
          p={["0px 10px","0px 20px", "0px 64px"]}
          flexDirection="column"
          alignItems="flex-start"
          gap="24px"
          alignSelf="stretch"
        >
          <HeaderContent title={resultData.nome_completo} />
          <BodyContent>
            <VStack  mx="auto" gap={5} >
              <Box  w="100%" bg="#fff" p={3}>


                <Text fontSize={"2xl"} fontWeight={"bold"} mb={4}>Dados pessoais</Text>
                <HStack w="100%" display="flex" justifyContent="space-between" mb={10}>
                  <VStack align={"flex-start"} >
                    <Text>Nome</Text>
                    <Text fontWeight="763">{resultData.nome_completo}</Text>
                    <Text>Idade</Text>
                    <Text fontWeight="763">{calcularIdade(new Date(resultData.nascimento))} anos</Text>
                  </VStack>
                  <VStack align={"flex-start"}>
                    <Text>Sexo</Text>
                    <Text fontWeight="763">{resultData.sexo}</Text>
                    <Text>E-mail</Text>
                    <Text fontWeight="763">{resultData.email}</Text>
                  </VStack>
                  <VStack align={"flex-start"}>
                    <Text>Ocupação</Text>
                    <Text fontWeight="763">{resultData.ocupacao}</Text>
                    <Text>Contato</Text>
                    <Text fontWeight="763">{resultData.fone|| "---"}</Text>
                  </VStack>
                </HStack>


                
              </Box>
              <VStack w="100%" flexDirection='column' mb={10} bg="#fff" p={3}>
                <Box w="100%">
                  <Text  fontWeight="400" fontSize="xl">
                    {!isLoading && "Perfil comportamental"}
                  </Text>
                  <Text fontSize={"xl"} fontWeight="763">
                    {!isLoading && resultData&&resultData.test[0].perfil}
                  </Text>
                </Box>
               
                <PerfilText letra={identificarLetraComMaiorNumero(resultData.test[0])} />
                {/* <OverView result={result} /> */}
                <BrainParts result={resultData.test[0]} />
                <BrainAnalysis result={resultData.test[0]} />
                <BrainChart result={resultData.test[0]} />
              </VStack>
            </VStack>
          </BodyContent>
        </Flex>
      )}
    </Template>
  );
}
