import React from "react";
import { Template } from "../componentes/template";
import { Flex, useBreakpointValue } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { HeaderContent } from "../componentes/content/header";
import { BodyContent } from "../componentes/content/body";
import { CardBlog } from "../componentes/cardBlog";
import Image from "../assets/cerebro.png"
export function HomePage() {
    const navigate = useNavigate()
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const handleNavigation = () => {
        navigate('/sign-in')
    }
    return (
    <Template home >
        <Flex 
            p={["0px 10px","0px 64px"]}
            flexDirection="column"
            alignItems="flex-start"
            gap="24px"
            alignSelf="stretch"
        >
            <HeaderContent title="Início" />
            <BodyContent>
                <CardBlog image={Image} title="Teste Teste"  url="oiasidoajioj"/>
            </BodyContent>
        </Flex>
    </Template>
  );
}
