import { Avatar, Box, Flex, HStack, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Text, Icon, VStack, Button, useToast } from "@chakra-ui/react";

import {  useState } from "react";
import { useNavigate } from "react-router-dom";
import { IPayLoad } from "../../../contexts/AuthProvider/types";
import { FaChevronDown } from "react-icons/fa";

import { useAuth  } from "../../../contexts/AuthProvider/useAuth";

interface ProfileProps {
  showProfileData: boolean | undefined;
}


export function Profile({ showProfileData }: ProfileProps) {
  
  
  const {user, logout} = useAuth()
  
  return (
    <Flex   align='center'  >

      <Menu>
        <MenuButton as={Button} rightIcon={<FaChevronDown />}>
          {user?.nome_completo }
        </MenuButton>
        <MenuList>
          <MenuItem 
            as="button"
            _hover={{bg:"blue.400"}}  
            onClick={()=>logout()}
          >
            Sair
          </MenuItem>
        </MenuList>
      </Menu>     
    </Flex>
    
  )
}