import React, { useEffect, useState } from "react";
import { Template } from "../componentes/template";
import { Button, Flex, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { HeaderContent } from "../componentes/content/header";
import { BodyContent } from "../componentes/content/body";
import { useQuestions } from "../contexts/QuestionsProvider/useQuiz";
import { identificarCampoComMaiorNumero } from "../utils/identificarCampoComMaiorNumero";
import { ITests } from "../contexts/QuestionsProvider/types";
export type ObjetoComMaiorNumero = {
    id: string;
    perfil: string;
    comportamento: string
    ponto_forte: string
    date_create: string;
};
export function MyTests() {
    const navigate = useNavigate()
    const {myTests, tests} = useQuestions()
    const [page, setPage] = useState(0)
    const [data, setData] = useState<ObjetoComMaiorNumero[] | null>(null)

    useEffect(()=>{
        myTests("0")
        setTimeout(()=>setData(identificarCampoComMaiorNumero(tests&&tests.data)))

        myTests("0")
    },[])

    const handleNavigation = () => {
        navigate('/questions')
    }
 
    useEffect(()=>{
        setData(identificarCampoComMaiorNumero(tests&&tests.data))
    },[tests])

    function handlePage(page: number){
        if(page>=0)
        myTests(String(page))
    }
      
    function viewResult(id: string){
        const x: any = tests?.data.filter((i)=>i.id===id)
        const resultData: ITests = x[0]
        navigate("/result",  {state:{resultData}})
    }
    return (
    <Template home >
        <Flex 
            p={["0px 10px","0px 64px"]}
            flexDirection="column"
            alignItems="flex-start"
            gap="24px"
            alignSelf="stretch"
        >
            <HeaderContent title="Meus testes">
                <Button  
                    bg="blue.400" 
                    colorScheme="blue" 
                    leftIcon={
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0003 4.16663V15.8333M4.16699 9.99996H15.8337" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    }
                    onClick={handleNavigation}
                >                
                    Novo teste
                </Button>
            </HeaderContent>
            <BodyContent>
                {tests&&
                <TableContainer 
                    display="flex"
                    bg="#FFF" 
                    borderRadius="8px" 
                    border="1px solid #EAECF0"
                    flexDirection="column"
                    alignItems="flex-start"
                    alignSelf="stretch"
                    w="100%"
                    fontSize={["xs", "md"]}
                >
                    <Table variant='simple'>
                        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                        <Thead>
                            <Tr>
                                <Th>Data</Th>
                                <Th>Perfil comportamental</Th>
                                <Th>Dominantes comportamentos</Th>
                                <Th>Ponto forte</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                data&&data.length>0&&data.map((i)=>{
                                    return(
                                        <Tr>
                                            <Td>{i.date_create}</Td>
                                            <Td>{i.perfil}</Td>
                                            <Td>{i.comportamento}</Td>
                                            <Td>{i.ponto_forte}</Td>
                                            <Td color="blue.400" fontWeight="bold" cursor={"pointer"} onClick={()=>viewResult(i.id)}>Ver mais</Td>
                                        </Tr>   
                                    )
                                })
                            }
                                                   
                        </Tbody>
                        <Tfoot justifyContent="space-between">
                            <Tr>
                                <Th>
                                    <Button 
                                        variant={"outline"} 
                                        onClick={()=>{
                                            setPage(page-1)
                                            handlePage(page-1)
                                        }} 
                                        isDisabled={page===0}
                                    >
                                        Anterior
                                    </Button>
                                </Th>
                                <Th></Th>
                                <Th>Página {page+1} de {Math.ceil(tests.total/20)}</Th>
                                <Th></Th>
                                <Th>
                                    <Button 
                                        variant={"outline"} 
                                        onClick={()=>{
                                            setPage(page+1)
                                            handlePage(page+1)
                                        }} 
                                        isDisabled={Math.ceil(tests.total/20)===page+1}
                                    >
                                        Próximo
                                    </Button>
                                </Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer> 
                }               
            </BodyContent>
        </Flex>
    </Template>
  );
}


