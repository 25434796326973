import React from "react";
import { Flex, HStack, Icon, IconButton, useBreakpointValue, Button } from "@chakra-ui/react";
import { RiMenuLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

import { Logo } from "./Logo";
import { Profile } from "./Profile";


interface PropsDrawer{
  sendInfo: ()=>void
  isPrint?: boolean
}
interface ErroType {
  response?: {
    data: {
      message: string
    }
  }
}


export function Header({sendInfo, isPrint}:PropsDrawer) {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  })
 
  const { user } = useAuth()

  return (
    <Flex
      as='header'                
      position={'fixed'}
      w="100%"
      top={0}
      zIndex={2}
      bg='white'
      align='center'
      justifyContent='space-between'
    >
      <HStack>
        {!isWideVersion &&!isPrint && (
          <IconButton
            aria-label="Open navigation"
            icon={<Icon as={RiMenuLine} />}
            fontSize='24'
            variant='unstyled'
            onClick={sendInfo}
            mr='2'
          >
          </IconButton>
        )}
        <Logo />
        {isWideVersion&&!isPrint && (      
          user?.is_admin
            ?
            <HStack>
              <ButtonMenu title="Início" url="/admin/home" />  
              <ButtonMenu title="Criações" url="/" />
            </HStack>
            :
            <HStack>
              {/* <ButtonMenu title="Início" url="/home" />   */}
              <ButtonMenu title="Meus testes" url="/my-tests" />
            </HStack>            
          )
        }
      </HStack>       

      {user&&user.id&&        
      <HStack>
        <Profile showProfileData={isWideVersion} />
      </HStack>
      }      
    </Flex>
  )
}

interface PropsButton{
  title: string
  url: string
}

function ButtonMenu({title, url}: PropsButton){
  const Location = useLocation()
  const navigate = useNavigate()
  return(
    <Button 
      display="flex"  
      h="36px"
      p="0px 16px"
      justifyContent="flex-end"
      alignItems="center"
      gap="10px"
      background={Location.pathname === url ?"#E5ECFF" :"#FFF"}
      color={Location.pathname === url ?"#406DFD" :"#667085"}
      onClick={()=>navigate(url)}
    >
      {title}
    </Button>
  )
}