// PrivateRoute.tsx
import React, { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthProvider/useAuth';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, checkAuth } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      await checkAuth(); // Assumindo que checkAuth é uma função assíncrona que verifica a autenticação
      setLoading(false);
    };

    checkAuthentication();
  }, [checkAuth]);

  if (loading) {
    // Pode exibir um loader ou uma mensagem de carregamento aqui
    return null;
  }

  return user !== null ? <>{children}</> : <Navigate to="/my-tests" />;
};

export default PrivateRoute;
