import React, { useEffect, useState } from "react";
import { Flex, Text, Grid, GridItem,  HStack, Td, TableContainer, Table, Thead, Tr, Th, Tbody, Tfoot, Button, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { Template } from "../../componentes/template";
import { HeaderContent } from "../../componentes/content/header";
import { BodyContent } from "../../componentes/content/body";
import { GrFormSearch } from "react-icons/gr";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { calcularIdade } from "../../utils/calculaIdade";
import { useQuestions } from "../../contexts/QuestionsProvider/useQuiz";
import { ITestsByUsers } from "../../contexts/QuestionsProvider/types";

export function AdminHomePage() {
    const navigate = useNavigate()
    const { requestIndication, indications } = useQuestions()
    useEffect(()=>{
        requestIndication()
    },[])
    return (
    <Template home >
        <Flex 
            p={["0px 10px","0px 20px", "0px 64px"]}
            flexDirection="column"
            alignItems="flex-start"
            gap="24px"
            alignSelf="stretch"
        >
            <HeaderContent title="Início" />
            <BodyContent>
                <Grid 
                    w="100%"
                    // display="flex"
                    templateColumns={{
                        base: 'repeat(1, 1fr)',
                        sm: 'repeat(1, 1fr)',
                        md: 'repeat(1, 1fr)',
                        lg: 'repeat(3, 1fr)',
                        xl: 'repeat(3, 1fr)',
                    }} 
                    gap={4}
                    // h="162px"
                    justifyContent="center"
                    alignItems="center"
                    alignSelf="stretch"
                >
                    <CardIndication  title="Total de usuários" value={`${indications.totalUsers} `} />
                    <CardIndication  title="Idade média" value={`${indications.idadeMedia} anos`} />
                    <CardIndication  title="Perfil mais comum" value={indications.perfilMaisRegistrado} percentage={indications.porcentagemPerfilMaisRegistrado} />
                    {/* <CardIndication  title="Ocupação mais comum" value="Advogado" percentage={55} /> */}
                </Grid>
            </BodyContent>
            <BodyContent>
                <TableUser />
            </BodyContent>
        </Flex>
    </Template>
  );
}


interface IProsIndicadores{
    title: string
    value: string
    percentage?: number
}
function CardIndication({title, value, percentage}:IProsIndicadores){

    
    return(

        <GridItem
            display="flex"
            flexDirection= "column"
            alignItems= "flex-start"
            flex= "1 0 0"
            h="full"
            minH="162px"
            w="100%"
            borderRadius= "16px"
            border= "1px solid var(--Gray-200, #EAECF0)"
            background= "var(--Base-White, #FFF)"
        >
            
            <Flex 
                padding="24px"
                flexDirection="column"
                alignItems="flex-start"
                gap="24px"
                justifyContent={"space-between"}
                alignSelf="stretch"
                w="100%"
            >
                <Text
                    color="#667085"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="24px"
                >{title}</Text>
                <Text
                    color="#101828"
                    fontSize="20px"
                    fontStyle="normal"
                    fontWeight={700}
                    lineHeight="30px"
                >{value}</Text>
                {
                    percentage&&
                    <Text
                        color="#667085"
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight={500}
                        lineHeight="20px"
                        
                    >
                        <span style={{color: "#406DFD"}}>{percentage}%</span> dos usuários
                    </Text>
                    
                }
            </Flex>  
        </GridItem>
        
    )
}


function TableUser(){
    const {findTestByUsers, testsByUsers, totalUsers} = useQuestions()
    const [pag, setPag] = useState(0)
    const [search, setSearch] = useState("")
    const navigate = useNavigate()

    const handlePage = async(pag: number)=>{
        await findTestByUsers({
            nome_completo: search,
            sexo: "",
            pag
        })
    }

    useEffect(()=>{
        findTestByUsers({
            nome_completo: "",
            sexo: "",
            pag: 0
        })
    },[])
    
    function viewResult(data: ITestsByUsers){
        navigate("/admin/result",  {state:{data}})
    }
    return(
        <TableContainer 
            display="flex"
            bg="#FFF" 
            borderRadius="8px" 
            border="1px solid #EAECF0"
            flexDirection="column"
            alignItems="flex-start"
            alignSelf="stretch"
            w="100%"
            fontSize={["xs", "md"]}
        >
            <HStack>
                <InputGroup maxW={250} m={2}>
                    <InputLeftElement pointerEvents='none'>
                    <GrFormSearch size={32}  />
                    </InputLeftElement>
                    <Input type='text' placeholder='Pesquisar usuário...' value={search} onChange={(e)=>setSearch(e.target.value)} />
                </InputGroup>
                <Button onClick={()=>handlePage(0)}>
                    Pesquisar
                </Button>
            </HStack>
            
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th>Nome</Th>
                        <Th>Idade</Th>
                        <Th>Sexo</Th>
                        <Th>E-mail</Th>
                        <Th>Ocupação</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        testsByUsers&&testsByUsers.length>0&&testsByUsers.map((i)=>{
                            return(
                                <Tr>
                                    <Td>{i.nome_completo}</Td>
                                    <Td>{calcularIdade(new Date(i.nascimento))}</Td>
                                    <Td>{i.sexo}</Td>
                                    <Td>{i.email}</Td>
                                    <Td>{i.ocupacao}</Td>
                                    {i.test.length>0?
                                        <Td color="blue.400" fontWeight="bold" cursor={"pointer"} onClick={()=>{viewResult(i)}}>Ver mais</Td>
                                        :<Td color="blue.400" fontWeight="bold"  ></Td>
                                    }
                                    
                                </Tr>   
                            )
                        })
                    }
                                            
                </Tbody>
                <Tfoot justifyContent="space-between">
                    <Tr>
                        <Th>
                            <Button 
                                variant={"outline"} 
                                onClick={()=>{
                                    setPag(pag-1)
                                    handlePage(pag-1)
                                }} 
                                isDisabled={pag===0}
                            >
                                Anterior
                            </Button>
                        </Th>
                        <Th></Th>

                        <Th></Th>
                        <Th textAlign="center">Página {pag+1} de {Math.ceil(totalUsers/20)}</Th>
                        <Th></Th>
                        <Th>
                            <Button 
                                variant={"outline"} 
                                onClick={()=>{
                                    setPag(pag+1)
                                    handlePage(pag+1)
                                }} 
                                isDisabled={Math.ceil(totalUsers/20)===pag+1}
                            >
                                Próximo
                            </Button>
                        </Th>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer> 
    )
}