import React, { useState } from "react";
import { Template } from "../componentes/template";
import { VStack, Link, Text, Button, FormControl, FormLabel, Input, FormErrorMessage, useToast, Flex, Box } from "@chakra-ui/react";
import {  useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider/useAuth";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";

type SignInFormData = {
  email: string;
  senha: string;
}

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { authenticate } = useAuth();

  const schema = yup.object().shape({
    email: yup.string().required('E-mail obrigatório').email('Insira um e-mail válido'),
    senha: yup.string().required('Senha obrigatória'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SignInFormData>({
    resolver: yupResolver(schema),
  });

  const handleSignIn: SubmitHandler<SignInFormData> = async (values) => {
    setIsLoading(true);
    try{
      await authenticate(values.email, values.senha);   
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Template isPrint >
      <Flex as='form' w='100%' h='100%' onSubmit={handleSubmit(handleSignIn)}>
        <Box w='100%' h='100%'>
          <VStack flex={1} maxW={900} w="full" mx="auto" align={"normal"} px={5} >
            <Text fontSize={"xl"} fontWeight={"semibold"} mb={5}>Entre com sua conta</Text>
            <FormControl isRequired mb={5}>
              <FormLabel>E-mail</FormLabel>
              <Input
                type='email'
                id='email'
                {...register('email')}
                onChange={(e) => setValue('email', e.target.value, { shouldValidate: true })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired mb={5}>
              <FormLabel>Senha</FormLabel>
              <Input
                type='password'
                id='senha'
                {...register('senha', { required: 'Senha obrigatória', minLength: 4 })}
                onChange={(e) => setValue('senha', e.target.value, { shouldValidate: true })}
              />
              <FormErrorMessage>{errors.senha?.message}</FormErrorMessage>
            </FormControl>


            <Button
              mt={5}
              bg="blue.400"
              color="white"
              colorScheme="blue"
              p={6}
              w="full"
              rounded={10}
              fontSize="md"
              isLoading={isLoading}
              type="submit"
            >
              Entrar
            </Button>
            <Link mt={5} onClick={()=>navigate("/sign-up")}>Registrar-se</Link>

          </VStack>
        </Box>
      </Flex>
    </Template>
  );
}
