import React, { ReactNode,  useState } from "react"
import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react"
import { Header } from "./Header"
import { Sidebar } from "./Sidebar"

interface Props{
    children: ReactNode
    title?: string
    home?: boolean
    isPrint?: boolean
}

export function Template({children, title, home, isPrint}: Props){

    const [ isopen, setIsOpen ] = useState(false)

    return(
        <VStack h="100vh"  maxH="100vh" >         
            <Header sendInfo={()=>setIsOpen(true)} isPrint={isPrint} />
            <Flex mt={20} w="100%" height={'full'} mx="auto" flexDirection={"column"} >
                
                <Sidebar isOpen={isopen} sendInfo={()=>setIsOpen(false)} />
                {home?<></>:
                    <Box textAlign={"center"}  mb={5}>
                        {title?
                        <Text fontSize={"2xl"} fontWeight={763} lineHeight={'normal'} letterSpacing={'0.54px'} >
                            {title}
                        </Text>
                        :
                        <Text marginX='auto'  w={400} fontSize={"4xl"} fontWeight={763} lineHeight={'normal'} letterSpacing={'0.54px'} >
                            Teste de <Text as="span" color="blue.400"> Perfil Comportamental</Text>
                        </Text>
                        }
                    </Box>   
                }             
                {children}
            </Flex>
        </VStack>
    )
}