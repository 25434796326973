import React from "react";
import { Template } from "../componentes/template";
import {
  VStack,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  Flex,
  Box,
  extendBaseTheme,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { INewUser } from "../contexts/AuthProvider/types";

const schema = yup.object().shape({
  nome_completo: yup.string().required("Nome é obrigatório"),
  nascimento: yup.string().required("Data de nascimento é obrigatória"),
  sexo: yup.string().required("Sexo é obrigatório"),
  fone: yup.string().required("Contato é obrigatório"),
  ocupacao: yup.string().required("Ocupação é obrigatória"),
  email: yup.string().required("E-mail é obrigatório").email("E-mail inválido"),
  senha: yup.string().required("Senha é obrigatória"),
  confirmeSenha: yup
    .string()
    .required("Confirmação de senha é obrigatória")
    .oneOf([yup.ref("senha")], "Senhas devem coincidir") as yup.StringSchema<string | undefined, FieldValues>,
});

export default function SignUp() {
  const navigate = useNavigate();
  const { novoUser } = useAuth();
  const toast = useToast();
  const { register, handleSubmit, formState, setValue } = useForm<INewUser>({
    resolver: yupResolver(schema) as any,
  });
  
  const handleNavigation = () => {
    navigate("/sign-in");
  };

  const onSubmit: SubmitHandler<INewUser> = async (data) => {
    localStorage.setItem("data-perfil-comportamental", JSON.stringify(data));
    console.log(data)
    try {
      await novoUser(data);
      handleNavigation();
    } catch (error) {
      toast({
        position: "top",
        description: "Erro ao criar usuário. Verifique suas informações e tente novamente.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Template isPrint>
      <Flex w="100%" h="100%">
        <Box w="100%" h="100%">
          <VStack flex={1} maxW={900} w="full" mx="auto" align={"normal"} px={5}>
            <Text fontSize={"xl"} fontWeight={"semibold"} mb={5}>
              Dados pessoais
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isRequired mb={5}>
                <FormLabel>Nome</FormLabel>
                <Input
                  placeholder="Digite seu nome"
                  {...register("nome_completo")}
                  onChange={(e) => setValue("nome_completo", e.target.value)}
                />
              </FormControl>
              <Flex flexDirection={["column", "row"]} w="full" justify="space-between">
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel>Data de nascimento</FormLabel>
                  <Input
                    placeholder="Digite sua nascimento"
                    {...register("nascimento")}
                    onChange={(e) => setValue("nascimento", e.target.value)}
                    type="date"
                  />
                </FormControl>
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel>Sexo</FormLabel>
                  <Select
                    placeholder="Escolha"
                    {...register("sexo")}
                    onChange={(e) => setValue("sexo", e.target.value)}
                  >
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                  </Select>
                </FormControl>
              </Flex>
              <Flex flexDirection={["column", "row"]} w="full" justify="space-between">
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                    <FormLabel>Ocupação</FormLabel>
                    <Input
                        {...register("ocupacao")}
                        placeholder="Digite sua ocupação"
                        onChange={(e) => setValue("ocupacao", e.target.value)}
                    />
                </FormControl>
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                    <FormLabel>Contato</FormLabel>
                    <Input
                        {...register("fone")}
                        onChange={(e) => setValue("fone", e.target.value)}
                    />
                </FormControl>
              </Flex>
              <FormControl isRequired mb={5}>
                <FormLabel>E-mail</FormLabel>
                <Input
                  placeholder="Digite seu e-mail"
                  {...register("email")}
                  type="email"
                  onChange={(e) => setValue("email", e.target.value)}
                />
              </FormControl>
              <Flex flexDirection={["column", "row"]} w="full" justify="space-between">
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel>Senha</FormLabel>
                  <Input
                    type="password"
                    {...register("senha")}
                    onChange={(e) => setValue("senha", e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired mb={5} flexBasis={["100%", "48%"]}>
                  <FormLabel>Confirme a senha</FormLabel>
                  <Input
                    type="password"
                    {...register("confirmeSenha")}
                    onChange={(e) => setValue("confirmeSenha", e.target.value)}
                  />
                </FormControl>
              </Flex>
              <Button
                mt={5}
                bg="blue.400"
                color="white"
                colorScheme="blue"
                p={6}
                w="full"
                rounded={10}
                fontSize="md"
                isLoading={formState.isSubmitting}
                type="submit"
              >
                Registrar
              </Button>

              <Link mt={10} onClick={()=>navigate("/sign-in")}>Entrar</Link>
            </form>
          </VStack>
        </Box>
      </Flex>
    </Template>
  );
}
