import React, { useEffect, useState } from "react";
import {
  VStack,
  Text,
  Button,
  Center,
  Divider,
  Stack,
  Flex,
  Box
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import { Template } from "../../componentes/template";
import { isMobile } from "react-device-detect";
import { useQuestions } from "../../contexts/QuestionsProvider/useQuiz";
import { calcularIdade } from "../../utils/calculaIdade";
import { ITests } from "../../contexts/QuestionsProvider/types";
import { identificarCampoComMaiorNumero } from "../../utils/identificarCampoComMaiorNumero";
import { identificarLetraComMaiorNumero } from "../../utils/identificarLetraComMaiorNumero";
import { HeaderContent } from "../../componentes/content/header";
import { BodyContent } from "../../componentes/content/body";
import { converterDataFormato } from "../../utils/converterDataFormato";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { identificarPerfil } from "../../utils/identificarPerfil";


// Lazy load components
const BrainParts = React.lazy(() => import("./componentsResult/brainParts"));
const BrainAnalysis = React.lazy(() => import("./componentsResult/brainAnalysis"));
const BrainChart = React.lazy(() => import("./componentsResult/brainChart"));
const PerfilText = React.lazy(() => import("./componentsResult/perfilText"));

export function Result() {
  const navigate = useNavigate();
  const {user} = useAuth()
  const { questions, responseQuestions } = useQuestions()
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const perfil = localStorage.getItem('data-perfil-comportamental')

    if (!perfil) {

      const result = getEmptyStringIndices(questions)
      result.map((i, index) => {
        localStorage.removeItem(`${index + 1}`)
        responseQuestions(index, '')
      });
    }
  }, [])
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);
 
  function getEmptyStringIndices(arr: string[]): number[] {
    const emptyIndices: number[] = [];

    for (let index = 0; index < arr.length; index++) {
      if (arr[index].length === 0) {
        emptyIndices.push(index);
      }
    }

    return emptyIndices;
  }
  const result: string[] = Array.from({ length: 25 }, (_, i) =>
    localStorage.getItem(`${i + 1}`) as string
  );
  
  const handleNavigation = () => {
    result.map((i, index) => {
      localStorage.removeItem(`${index + 1}`)
    });

    navigate("/my-tests");
  };
  function contarOcorrenciasLetra(letra: string): number {
    return result.filter((opcao) => opcao === letra).length;
  }
  const fone = `${process.env.REACT_APP_PHONE_ANALISTA}`

  
  const location = useLocation();
  const resultRoute: ITests = location.state?.resultData;


  const resultData: ITests = resultRoute?resultRoute:{
    a_dominante: contarOcorrenciasLetra("A")*4,
    c_estavel: contarOcorrenciasLetra("C")*4,
    i_influente: contarOcorrenciasLetra("I")*4,
    o_analitico: contarOcorrenciasLetra("O")*4,
    userId: "",
    date_create: String(new Date()),
    id: ""
  }
  const gerarPDF = () => {
    localStorage.setItem("@result-test", JSON.stringify(resultData))
    window.open(`/result/pdf`, '_blank');
    // navigate('/result/pdf', {state:{resultData}})
  };

  const handleWhatsApp = () => {
    if (user && user.ocupacao) {

      const message = `Olá, me chamo ${user.nome_completo.toLocaleUpperCase()}, sexo ${user.sexo}, ${calcularIdade(new Date(user.nascimento))} anos, ocupação ${user.ocupacao.toLocaleUpperCase()}, o meu perfil é ${String(resultData.perfil||identificarPerfil(identificarLetraComMaiorNumero(resultData))).toLocaleUpperCase()}.
  Resultados: ${resultData.i_influente}% influente, ${resultData.c_estavel}% estável, ${resultData.a_dominante}% dominante e ${resultData.o_analitico}% analítico. Pensante ${((resultData.o_analitico + resultData.i_influente) / 2)}%, emoção ${((resultData.i_influente + resultData.c_estavel) / 2) }%, atuante ${((resultData.c_estavel + resultData.a_dominante) / 2)}% e razão ${((resultData.o_analitico + resultData.a_dominante) / 2)}%.
  `

      if (isMobile) {
        // Abrir o aplicativo do WhatsApp no celular
        window.open(`whatsapp://send?phone=${fone}&text=${message}`)
      } else {
        // Abrir o WhatsApp Web no computador
        window.open(`https://web.whatsapp.com/send?phone=${fone}&text=${message}`, '_blank')
      }

    }
  }
  const x = identificarCampoComMaiorNumero([resultData])

  return (
    <Template title=" ">
      {isLoading ? (
        <Center w={"100vw"} h={"100vh"} pos={"fixed"} mt={-20}>
          <Player
            src="https://lottie.host/d8a484d2-1443-4d23-8f14-bb518494302c/qWhNCg22gy.json"
            className="player"
            loop
            autoplay
            style={{
              height:"350px"
            }}
          />
        </Center>
      ) : (
        <Flex 
          p={["0px 10px","0px 20px", "0px 64px"]}
          flexDirection="column"
          alignItems="flex-start"
          gap="24px"
          alignSelf="stretch"
          mb={10}
        >
          <HeaderContent title={`Teste ${converterDataFormato(resultData.date_create)}`} />
          <BodyContent>
            <VStack  mx="auto" gap={5} >              
              <Box  w="100%" bg="#fff" p={3}>
                <Text  fontWeight="400">
                {!isLoading && "Seu perfil comportamental é "}
                </Text>
                <Text fontSize={"xl"} fontWeight="763">
                {!isLoading && x&&x[0].perfil} 
                </Text>
              </Box>
              
              <VStack w="100%" flexDirection='column'bg="#fff" p={3}>
                <PerfilText letra={identificarLetraComMaiorNumero(resultData)} />
                {/* <OverView result={result} /> */}
                <BrainParts result={resultData} />
                <BrainAnalysis result={resultData} />
                <BrainChart result={resultData} />

                <Text fontWeight={400}>
                  Ficou alguma dúvida sobre nossa análise ou deseja conversar com algum dos nossos analistas?
                </Text>
                <Stack display='flex' w="100%" mt={5} mb={10} flexDirection={['column-reverse', 'row']}>
                  <Button
                    borderWidth={1}
                    borderColor="gray.300"
                    variant="ghost"
                    p={6}
                    w="full"
                    rounded={10}
                    fontSize="md"
                    onClick={handleNavigation}
                  >
                    Início
                  </Button>
                  <Button
                    bg="blue.400"
                    color="white"
                    colorScheme="blue"
                    p={6}
                    w="full"
                    rounded={10}
                    fontSize="md"
                    onClick={handleWhatsApp}
                  >
                    Conversar com analista
                  </Button>
                  <Button
                    borderWidth={1}
                    borderColor="gray.300"
                    variant="ghost"
                    p={6}
                    w="full"
                    rounded={10}
                    fontSize="md"
                    onClick={gerarPDF}
                  >
                    PDF
                  </Button>
                </Stack>
                
              </VStack>

            </VStack>
          </BodyContent>
        </Flex>
      )}
    </Template>
  );
}
