
import logo from "../../../assets/analise.png"


export function Logo() {
  return (
    <>
    <img height={35} width={80} src={logo} alt="Análise de perfil" />
    </>
    
  )
}