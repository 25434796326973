// import React from "react";
// import { Template } from "../componentes/template";
import { Player } from "@lottiefiles/react-lottie-player";
// import { Box, Button, Flex, HStack, Text, VStack, Image, useBreakpointValue } from "@chakra-ui/react";
import Vector from "../assets/Background pattern-min.svg";
// import { useNavigate } from "react-router-dom";

// export function LandingPage() {
//     const navigate = useNavigate()
//     const isMobile = useBreakpointValue({ base: true, lg: false });
//     const handleNavigation = () => {
//         navigate('/sign-in')
//     }
//     return (
//     <Template title=" " isPrint>
//         {isMobile?
//         <VStack maxW={900} mx="auto" position={"relative"}  p={5} >
//             <Image src={Vector} alt="" width='full'height='100%' position={'absolute'} top={0} objectFit="cover" objectPosition= "top" />

//             <Text fontSize={"4xl"} fontWeight={763} lineHeight={'normal'} letterSpacing={'0.54px'} >
//                 Teste de <Text as="span" color="blue.400"> Perfil Comportamental</Text>
//             </Text>
//             <Text fontSize={['md','xl','2xl']} mt={5} fontWeight={"400"}>
//                 Saiba como o seu tipo de personalidade influencia diversas áreas da sua vida
//             </Text>
//             <VStack>
//                 <Player
//                     src="https://lottie.host/c4f19b3d-cbf2-4600-9d16-33e9a4b60e22/lJjhgI1JoC.json"
//                     className="player"
//                     loop
//                     autoplay
//                 />
//                 <VStack mx="auto" >
//                     <Text 
//                         fontSize={['md','xl','2xl']}
//                         fontWeight={"semibold"}
//                     > 
//                         Seja você mesmo e responda de maneira honesta para descobrir o seu perfil comportamental
//                     </Text>
//                     <Button 
//                         mt={5}
//                         bg="blue.400" 
//                         color="white" 
//                         colorScheme="blue"
//                         p={6}
//                         w="full"
//                         rounded={10}
//                         fontSize="md"
//                         onClick={handleNavigation} 
//                     >
//                         Começar agora
//                     </Button>
//                 </VStack>  
//             </VStack>

//         </VStack>  
//         :
//             <HStack align="center" w="100%"  height='100%' position={"relative"} justify="center"  alignSelf="center"  mt={-10}>

//                 <Image src={Vector} alt="" width='full'height='100%' position={'absolute'} top={0} objectFit="cover" objectPosition= "top" />

//                 <HStack maxW={'80rem'} h='100%' >
//                     <VStack w='50%' pl={[0,5]}>
//                         <Text fontSize='56px' fontWeight={763} lineHeight={'normal'} letterSpacing={'0.54px'} >
//                             Teste de <Text as="span" color="blue.400"> Perfil Comportamental</Text>
//                         </Text>
//                         <Text fontSize='20px' mt={5} fontWeight={"400"}>
//                             Saiba como o seu tipo de personalidade influencia diversas áreas da sua vida
//                         </Text>
//                         <Text 
//                             mt={10}
//                             fontSize='18px'
//                             fontWeight={"bold"}
//                         > 
//                             Seja você mesmo e responda de maneira honesta para descobrir o seu perfil comportamental
//                         </Text>
//                         <Button 
//                             mt={5}
//                             bg="blue.400" 
//                             color="white" 
//                             colorScheme="blue"
//                             p={6}
//                             w="full"
//                             rounded={10}
//                             fontSize="md"
//                             onClick={handleNavigation} 
//                         >
//                             Começar agora
//                         </Button>
//                     </VStack>   
//                     <VStack w='50%' align={"flex-start"}>
//                         <Player
//                             src="https://lottie.host/c4f19b3d-cbf2-4600-9d16-33e9a4b60e22/lJjhgI1JoC.json"
//                             className="player"
//                             loop
//                             autoplay
//                         /> 
//                     </VStack>
//                 </HStack>
//             </HStack>
//         }
//     </Template>
//   );
// }import React from "react";
import { motion } from "framer-motion";
import { VStack, Image, useBreakpointValue, Center, Stack, Box, Text, Button, HStack } from "@chakra-ui/react";
import eventoLogo from "../assets/moneyoffer.png";
import devLogo from "../assets/codelabs.png";
import { useNavigate } from "react-router-dom";
import { Template } from "../componentes/template";


export function LandingPage() {
    const handleNavigation = () => {
        navigate('/sign-in')
    }
    const navigate = useNavigate();
    const isMobile = useBreakpointValue({ base: true, lg: false });

    // Variáveis de animação
    const logoContainerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { delay: 0.5, duration: 1.5 } },
    };

    const eventoLogoVariants = {
        hidden: { opacity: 0, scale: 0.5 },
        visible: { opacity: 1, scale: 1, transition: { delay: 0.5, duration: 1.5 } },
    };

    const mais = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, scale: 1, transition: { delay: 1, duration: 1.5 } },
    };

    const devLogoVariants = {
        hidden: { opacity: 0, x: -300 },
        visible: { opacity: 1, x: 0, transition: { delay: 2, duration: 1.5 } },
    };

    const devLogoVariants2 = {
        hidden: { opacity: 0, y: -300 },
        visible: { opacity: 1, y: 0, transition: { delay: 2, duration: 1.5 } },
    };

    return (
        <VStack height="100vh" justifyContent="center" alignItems="center" backgroundColor="black">
            <Center>
                <motion.div initial="hidden" animate="visible" variants={logoContainerVariants}>
                    <Stack direction={['column', 'row']} align={'center'} spacing='6'>
                        <Box backgroundColor={'black'} zIndex={2}>
                            <motion.div variants={eventoLogoVariants}>
                                <Image height={'100px'} src={eventoLogo} alt="Evento Logo" />
                            </motion.div>
                        </Box>
                        <motion.div variants={devLogoVariants} style={{ marginRight: -30 }}>
                            <Text fontSize="3xl" color="white">+</Text>
                        </motion.div>
                        <Box mt={[-10, 0]}>
                            <motion.div variants={devLogoVariants}>
                                <Image minH={'100px'} src={devLogo} alt="Desenvolvedora Logo" />
                            </motion.div>
                        </Box>
                    </Stack>
                </motion.div>
            </Center>
            {/* Add the new motion component */}
            <motion.div

                initial={{ opacity: 1, y: 1000 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 4.3, duration: 1.3, ease: "easeInOut" }}
                style={{ position: 'absolute', zIndex: 4 }}
            >
                <VStack w={'100vw'} h='100vh' backgroundColor="white" p={4} borderRadius="md">
                    <Template title=" " isPrint>
                        {isMobile ?
                            <VStack maxW={900} mx="auto" position={"relative"} p={5} >
                                <Image src={Vector} alt="" width='full' height='100%' position={'absolute'} top={0} objectFit="cover" objectPosition="top" />

                                <Text fontSize={"4xl"} fontWeight={763} lineHeight={'normal'} letterSpacing={'0.54px'} >
                                    Teste de <Text as="span" color="blue.400"> Perfil Comportamental</Text>
                                </Text>
                                <Text fontSize={['md', 'xl', '2xl']} mt={5} fontWeight={"400"}>
                                    Saiba como o seu tipo de personalidade influencia diversas áreas da sua vida
                                </Text>
                                <VStack>
                                    <Player
                                        src="https://lottie.host/c4f19b3d-cbf2-4600-9d16-33e9a4b60e22/lJjhgI1JoC.json"
                                        className="player"
                                        loop
                                        autoplay
                                    />
                                    <VStack mx="auto" >
                                        <Text
                                            fontSize={['md', 'xl', '2xl']}
                                            fontWeight={"semibold"}
                                        >
                                            Seja você mesmo e responda de maneira honesta para descobrir o seu perfil comportamental
                                        </Text>
                                        <Button
                                            mt={5}
                                            bg="blue.400"
                                            color="white"
                                            colorScheme="blue"
                                            p={6}
                                            w="full"
                                            rounded={10}
                                            fontSize="md"
                                            onClick={handleNavigation}
                                        >
                                            Começar agora
                                        </Button>
                                    </VStack>
                                </VStack>

                            </VStack>
                            :
                            <HStack align="center" w="100%" height='100%' position={"relative"} justify="center" alignSelf="center" mt={-10}>

                                <Image src={Vector} alt="" width='full' height='100%' position={'absolute'} top={0} objectFit="cover" objectPosition="top" />

                                <HStack maxW={'80rem'} h='100%' >
                                    <VStack w='50%' pl={[0, 5]}>
                                        <Text fontSize='56px' fontWeight={763} lineHeight={'normal'} letterSpacing={'0.54px'} >
                                            Teste de <Text as="span" color="blue.400"> Perfil Comportamental</Text>
                                        </Text>
                                        <Text fontSize='20px' mt={5} fontWeight={"400"}>
                                            Saiba como o seu tipo de personalidade influencia diversas áreas da sua vida
                                        </Text>
                                        <Text
                                            mt={10}
                                            fontSize='18px'
                                            fontWeight={"bold"}
                                        >
                                            Seja você mesmo e responda de maneira honesta para descobrir o seu perfil comportamental
                                        </Text>
                                        <Button
                                            mt={5}
                                            bg="blue.400"
                                            color="white"
                                            colorScheme="blue"
                                            p={6}
                                            w="full"
                                            rounded={10}
                                            fontSize="md"
                                            onClick={handleNavigation}
                                        >
                                            Começar agora
                                        </Button>
                                    </VStack>
                                    <VStack w='50%' align={"flex-start"}>
                                        <Player
                                            src="https://lottie.host/c4f19b3d-cbf2-4600-9d16-33e9a4b60e22/lJjhgI1JoC.json"
                                            className="player"
                                            loop
                                            autoplay
                                        />
                                    </VStack>
                                </HStack>
                            </HStack>
                        }
                    </Template>
                </VStack>
            </motion.div>
        </VStack>
    );
}
